


import Storage from "@/util/Storage";
import {DelComment, getSecondComment} from "@/Api/Group";
import {SecondCommentType} from "@/Api/Group/indexType";
import {Toast} from "vant";

// 删除评论
export function delComment(id:string|number) {
    const userId = Storage.GetData_ && Storage.GetData_("userId")
    const token = Storage.GetData_ && Storage.GetData_("token")
    return Promise.resolve( DelComment({userId,token},{ id }).then(res=>{
        Toast("删除成功")
        return res.data
    }) )
}

// 查看二级评论
export function getGroupSecond(upData:SecondCommentType) {
    const userId = Storage.GetData_ && Storage.GetData_("userId")
    const token = Storage.GetData_ && Storage.GetData_("token")
    return Promise.resolve( getSecondComment({userId,token},upData).then(res=>{
        return res.data
    }) )
}
